import { Assets } from "./Assets";

const DATA = {
  navLinks: [
    {
      link: "About Me",
      path: "/aboutme",
    },
    {
      link: "Projects",
      path: "/projects",
    },
    {
      link: "Who am I?",
      path: "",
    },
  ],

  dropDownLinks: [
    {
      link: "Software Engineering",
      path: "/software-eng",
    },
    {
      link: "Entrepreneur",
      path: "/entrepreneur",
    },
    {
      link: "Tech Training",
      path: "/tech",
    },
  ],

  projectsHomePage: [
    {
      name: "Knowledge City Website",
      icon: Assets.images.kkc,
      appDesc: `Knowledge City is more than just an educational platform—it is a
        community where learners from all walks of life can come together to expand 
        their knowledge, build valuable skills, and take control of their personal 
        and professional development. Through innovative technologies, expert-led courses, 
        and a focus on inclusivity and accessibility, Knowledge City is leading the charge 
        in shaping the future of education. Join us and embark on a journey of growth and
         discovery in the digital age.`,
      responsibility: `
        Delegating tasks to team members,
        Executing the full software development lifecycle,
        Helping team members fix issues,
        Assigning tasks in Github Projects,
        Organizing work and setting up the initial app Developing flowcharts,
        Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
        Communicating goals to team members,
        Producing specifications and determining operational feasibility,
        Integrating software components into a fully functional system,
        Developing software verification plans and quality assurance procedures,
        Nurturing team members' strengths and identifying areas for improvement,
      `,
      link: ``,
      technologies: `
        React Native,
        Typescript,
        Redux / Redux Toolkit,
        Git / Gitlab,
        Jira,
        Android Studio / Emulator,
        Xcode and Simlator,
        VS-Code,
      `,
      category: `Front-End Projects`,
    },
    {
      name: "D'roid Technologies Website",
      icon: Assets.images.droid,
      appDesc: `D'roid Technologies is a forward-thinking, product-led company specializing 
      in offering innovative tech solutions that cater to the needs of businesses and 
      individuals in today’s rapidly evolving digital world. Established with a vision 
      to empower enterprises and individuals alike, D'roid Technologies focuses on delivering 
      high-quality mobile and web development services, alongside providing exceptional user 
      experiences through modern software solutions.`,
      responsibility: `
        Delegating tasks to team members,
        Executing the full software development lifecycle,
        Helping team members fix issues,
        Assigning tasks in Github Projects,
        Organizing work and setting up the initial app Developing flowcharts,
        Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
        Communicating goals to team members,
        Producing specifications and determining operational feasibility,
        Integrating software components into a fully functional system,
        Developing software verification plans and quality assurance procedures,
        Nurturing team members' strengths and identifying areas for improvement,
      `,
      link: ``,
      technologies: `
        React Native,
        Typescript,
        Redux / Redux Toolkit,
        Git / Gitlab,
        Jira,
        Android Studio / Emulator,
        Xcode and Simlator,
        VS-Code,
      `,
      category: `Front-End Projects`,
    },
    {
      name: "Rapid Transfer Mobile",
      appDesc: `Rapidtransfer is a mobile application that allows individuals to remit monies to beneficiaries in any of the thirty-three (33) African countries where Ecobank operates through a payment card for funding`,
      icon: Assets.images.rapid_transfer,
      responsibility: `
        Delegating tasks to team members,
        Executing the full software development lifecycle,
        Helping team members fix issues,
        Assigning tasks in Github Projects,
        Organizing work and setting up the initial app Developing flowcharts,
        Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
        Communicating goals to team members,
        Producing specifications and determining operational feasibility,
        Integrating software components into a fully functional system,
        Developing software verification plans and quality assurance procedures,
        Nurturing team members' strengths and identifying areas for improvement,
      `,
      link: ``,
      technologies: `
        React Native,
        Typescript,
        Redux / Redux Toolkit,
        Git / Gitlab,
        Jira,
        Android Studio / Emulator,
        Xcode and Simlator,
        VS-Code,
      `,
      category: `Mobile App Projects`,
    },
    {
      name: "Ecobank Pay Mobile",
      icon: Assets.images.eco_bank,
      appDesc: `The Ecobank Pay app is a payment management tool for merchants that aims to streamline the payment process.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Mobile App Projects`,
    },
    {
      name: "Ecobank Website",
      icon: Assets.images.eco_logo,
      appDesc: `Ecobank, whose official name is Ecobank Transnational Inc. (ETI), is a pan-African banking conglomerate, with banking operations in 33 African countries. It is the leading independent regional banking group in West Africa and Central Africa, serving wholesale and retail customers`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      link: `https://ecobank.com`,
      category: `Front-End Projects`,
    },
    {
      name: "E-Process Website",
      icon: Assets.images.e_process,
      appDesc: `We bring to the table deep industry experience and a track record of consistently surpassing customer expectations, as we collaborate with clients to solve their most pressing challenges from strategy through execution.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      link: `https://eprocessconsulting.com/`,
      category: `Front-End Projects`,
    },
    {
      name: "D'roid Carousel",
      icon: Assets.images.npm_pic,
      appDesc: `The Carousel component is a customizable React component that creates a responsive and interactive image slider. It's built with TypeScript and styled-components, providing a smooth sliding experience with navigation controls and indicators.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "D'roid Check Box",
      icon: Assets.images.npm_pic,
      appDesc: `AppCheckbox is a customizable React checkbox component built with TypeScript. It provides a simple way to add checkbox functionality to your React applications with a clean, modern design.

      `,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "Cash Basket Web",
      icon: Assets.images.cash_b,
      appDesc: `Cash Basket is a global financial services product for African immigrants. With Cash Basket, you can instantly transfer money from the UK, US and Canada to Africa, Asia, Europe and South America.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
    {
      name: "D'roid Button",
      icon: Assets.images.npm_pic,
      appDesc: `Button is a customizable React component for creating buttons with various styling options. It's written in TypeScript for better type safety and maintainability.

      Features
      Customizable appearance (background color, text color, dimensions)
      Configurable margins
      Optional icon support
      Disabled state handling
      TypeScript support for type safety`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "D'roid Tool Tip",
      icon: Assets.images.npm_pic,
      appDesc: `The Tooltip component is a reusable React component that displays a small tooltip when hovering over a specific element. It is inspired by the Material UI design and written in TypeScript for better type safety and maintainability.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "D'roid Card",
      icon: Assets.images.npm_pic,
      appDesc: `The Card component is a flexible and customizable UI component for displaying content in a card format. It supports various elements such as a title, subtitle, icon, image, content, actions, and tags, making it suitable for a wide range of use cases.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "Drizzle Den Website",
      icon: Assets.images.drizzle,
      appDesc: `A global restaurant chain that serves millions of customers every day.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
    {
      name: "Leads Prime Secondary School",
      icon: Assets.images.leadsPrime,
      appDesc: `This is the website of a secondary school in Lagos Nigeria. The have both primary and secondary sections and parents review have gone up the charts de to the new look of the website`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
    {
      name: "D'roid Alert Notification",
      icon: Assets.images.npm_pic,
      appDesc: `AlertNotification is a pre-compiled React component for displaying alert messages with different types (success, error, warning). It's built with TypeScript and styled-components, providing a clean and modern design that's ready to use out of the box.

      Features
      Three alert types: success, error, and warning
      Customizable message content
      Closable alerts
      Pre-styled with a modern design
      TypeScript support for type safety`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: `https://www.npmjs.com/package/@droid-tech/react-ts-alert-notification`,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "Knowledge City Mobile",
      icon: Assets.images.kkc,
      appDesc: `Knowledge City is more than just an educational platform—it is a
        community where learners from all walks of life can come together to expand 
        their knowledge, build valuable skills, and take control of their personal 
        and professional development. Through innovative technologies, expert-led courses, 
        and a focus on inclusivity and accessibility, Knowledge City is leading the charge 
        in shaping the future of education. Join us and embark on a journey of growth and
         discovery in the digital age.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Mobile App Projects`,
    },
    {
      name: "Ecobank Omni Lite Mobile",
      icon: Assets.images.ecobank_lite,
      appDesc: `With its easy to use features, the Omni Lite app empowers Commercial Banking clients to perform all their regular banking transactions and payments conveniently and in a secure manner.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Mobile App Projects`,
    },
    {
      name: "D'roid Avatar",
      icon: Assets.images.npm_pic,
      appDesc: `Avatar is a customizable React component for displaying user avatars with various styling options. It's written in TypeScript for better type safety and maintainability.

      Features
      Customizable appearance (variant, size, colors)
      Supports image and text avatars
      Optional badge support
      Grouped avatars
      TypeScript support for type safety`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "D'roid Text Area",
      icon: Assets.images.npm_pic,
      appDesc: `TextArea is a customizable React functional component that renders a textarea input field. It allows for various styling and layout options through its props, making it flexible and easy to use in different UI designs.

      Features
      Customizable Border: Adjust border color, width, and radius.
      Flexible Dimensions: Control the number of rows and columns.
      Margin Control: Set top and bottom margins for spacing.
      Placeholder Text: Define custom placeholder text.
      Default Values: Pre-configured rows and columns for quick setup.
      CSS Integration: Supports className and inline styles.
      Lightweight: Simple, focused functionality.
      TypeScript Support: Provides type safety and IntelliSense.
      `,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "Ecobank Authenticator Mobile",
      icon: Assets.images.ecobank_auth,
      appDesc: `This app will generate a security code which an existing customer performing a transaction on Ecobank Online and Ecobank omni lite can use to further secure their transaction.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Mobile App Projects`,
    },
    {
      name: "Access Bank Mobile App",
      icon: Assets.images.acces_bank,
      appDesc: `The Access More App is an innovative mobile payment application that offers a more than banking experience. The App is built to help our customers consummate their financial transactions, payment solutions and enjoy a lifestyle experience.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Mobile App Projects`,
    },
    {
      name: "Ekenedilichukwu Portfolio",
      icon: Assets.images.main_logo,
      appDesc: `Ths website contains all the information about Ekene. What he has done, how he achieved it and the path he took to get there.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
    {
      name: "D'roid Loader",
      icon: Assets.images.npm_pic,
      appDesc: `The Loader component is a customizable React component that displays a spinning loader animation. It's built using styled-components and TypeScript for better styling control and type safety.

      Features
      Customizable size
      Customizable color
      Smooth spinning animation
      TypeScript support for type safety`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "D'roid Tabs",
      icon: Assets.images.npm_pic,
      appDesc: `The Tabs component is a customizable React component that creates a tabbed interface. It's written in TypeScript for better type safety and maintainability.

      Features
      Customizable tab labels
      Customizable tab content
      Customizable styles for tabs, tab list, tab panels, and active tabs
      TypeScript support for type safety`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `NPM Projects`,
    },
    {
      name: "Okoli Brothers Web",
      icon: Assets.images.okolibro,
      appDesc: `Okoli Brothers is a shipping company with the main aim 
      of commuting goods and services between continents.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
    {
      name: "Order It",
      icon: Assets.images.order_it,
      appDesc: `Order It prides its self for getting your needs to your door step on time and intact.`,
      responsibility: `
         Delegating tasks to team members,
         Executing the full software development lifecycle,
         Helping team members fix issues,
         Assigning tasks in Github Projects,
         Organizing work and setting up the initial app Developing flowcharts,
         Guiding layouts and documentation requirements and solutions Writing well-designed testable code,
         Communicating goals to team members,
         Producing specifications and determining operational feasibility,
         Integrating software components into a fully functional system,
         Developing software verification plans and quality assurance procedures,
         Nurturing team members' strengths and identifying areas for improvement,
       `,
      link: ``,
      technologies: `
         React Native,
         Typescript,
         Redux / Redux Toolkit,
         Git / Gitlab,
         Jira,
         Android Studio / Emulator,
         Xcode and Simlator,
         VS-Code,
       `,
      category: `Front-End Projects`,
    },
  ],

  testimonials: [
    {
      words: `I have had the pleasure of working with Ekene on
      several projects, and I can confidently say that his expertise
      and dedication are unmatched. From the initial consultation to the
      final delivery, he demonstrated a deep understanding of
      our needs and provided innovative solutions that exceeded our
      expectations. The software he developed for us is robust,
      user-friendly, and has significantly improved our operational
      efficiency. His commitment to quality and customer satisfaction
      is evident in every interaction. I highly recommend Ekene to any 
      organization looking for top-tier technology solutions.`,
      fullName: "Mark Ettan",
      title: "Founder",
      company: "LEADPAC Foundation",
    },
    {
      words: `I have learnt a lot from his leadership skills and his ability to carry others along.`,
      fullName: "Udoh Mfon",
      title: "CEO",
      company: "School of Creativity",
    },
  ],
};

export { DATA };

const Assets = {
  images: {
    main_logo: require("../../Assets/images/png/ekene_logo.png"),
    social_github: require("../../Assets/images/png/github.png"),
    social_linkedIn: require("../../Assets/images/png/linkedin.png"),
    social_twitter: require("../../Assets/images/png/twitter.png"),
    face_photo: require("../../Assets/images/png/backdrop.png"),
    drop_down: require("../../Assets/images/png/cheveron-down.png"),
    drop_down_b: require("../../Assets/images/png/cheveron-black-down.png"),
    image_left: require("../../Assets/images/png/image_left.png"),
    image_right: require("../../Assets/images/png/image_right.png"),
    who_is: require("../../Assets/images/png/whois.png"),
    mobile: require("../../Assets/images/png/Mobile.png"),
    web: require("../../Assets/images/png/Code.png"),
    layout: require("../../Assets/images/png/Layout.png"),
    version_control: require("../../Assets/images/png/Versioncontrol.png"),
    back_end: require("../../Assets/images/png/backend.png"),
    kkc: require("../../Assets/images/png/kkc.png"),
    droid: require("../../Assets/images/png/D'roid_new_logo.jpg"),
    rapid_transfer: require("../../Assets/images/png/rapidtransfer.png"),
    eco_bank: require("../../Assets/images/png/ecobank.png"),
    trainer: require("../../Assets/images/png/trainer.png"),
    entre: require("../../Assets/images/png/entre.png"),
    gMail: require("../../Assets/images/png/Gmail.png"),
    whatsApp: require("../../Assets/images/png/Whatsapp.png"),
    intro: require("../../Assets/images/png/Frame33.png"),
    suit_pic: require("../../Assets/images/png/softwareEng.png"),
    cap_pic: require("../../Assets/images/png/software-Eng.png"),
    npm_pic: require("../../Assets/images/png/npm.png"),
    eco_logo: require("../../Assets/images/png/ecobank_logo.png"),
    e_process: require("../../Assets/images/png/e_process.png"),
    cash_b: require("../../Assets/images/png/cash_basket.png"),
    max: require("../../Assets/images/png/Maximus.png"),
    order_it: require("../../Assets/images/png/orderit.png"),
    okolibro: require("../../Assets/images/png/Okolibrothers.png"),
    leadsPrime: require("../../Assets/images/png/leadsprime.png"),
    drizzle: require("../../Assets/images/png/drizzle.png"),
    ecobank_lite: require("../../Assets/images/png/ecobank_lite.png"),
    ecobank_auth: require("../../Assets/images/png/e_auth.png"),
    acces_bank: require("../../Assets/images/png/Access_Bank_Logo.png"),
    marriage: require("../../Assets/images/png/marrige.png"),
    dev: require("../../Assets/images/png/dev.png"),
    tech_two: require("../../Assets/images/png/teach2.png"),
    tech_one: require("../../Assets/images/png/tech.png"),
    ent: require("../../Assets/images/png/ent.png"),
  },
};

export { Assets };
